import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type DeepArEffects = {
  __typename?: 'DeepArEffects';
  arEffects?: Maybe<Scalars['Boolean']>;
  autoFraming?: Maybe<Scalars['Boolean']>;
  segmentation?: Maybe<Scalars['Boolean']>;
};

export type DeepArEffectsInput = {
  arEffects?: Maybe<Scalars['Boolean']>;
  autoFraming?: Maybe<Scalars['Boolean']>;
  segmentation?: Maybe<Scalars['Boolean']>;
};

export enum EnvironmentType {
  Any = 'ANY',
  Cherry = 'CHERRY',
  Web = 'WEB',
}

export type Mutation = {
  __typename?: 'Mutation';
  createSettings?: Maybe<Settings>;
  createTask?: Maybe<Task>;
  deleteUserSubmission?: Maybe<UserSubmission>;
  updateSettings?: Maybe<Settings>;
  updateTask?: Maybe<Task>;
  updateTaskStatus?: Maybe<Task>;
};

export type MutationCreateSettingsArgs = {
  input?: Maybe<SettingsInput>;
};

export type MutationCreateTaskArgs = {
  input?: Maybe<TaskInput>;
};

export type MutationDeleteUserSubmissionArgs = {
  pk: Scalars['ID'];
  sk: Scalars['String'];
};

export type MutationUpdateSettingsArgs = {
  expectedVersion: Scalars['Int'];
  input?: Maybe<SettingsUpdateInput>;
};

export type MutationUpdateTaskArgs = {
  expectedVersion: Scalars['Int'];
  input?: Maybe<TaskUpdateInput>;
  pk: Scalars['ID'];
};

export type MutationUpdateTaskStatusArgs = {
  input?: Maybe<TaskStatusUpdateInput>;
  pk: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  getSettings?: Maybe<Settings>;
  getTask?: Maybe<Task>;
  listTaskSubmissions?: Maybe<UserSubmissionsConnection>;
  listTasks: TaskListResult;
  listUserSubmissions: UserTaskListResult;
  listUserTasks: UserTaskListResult;
};

export type QueryGetTaskArgs = {
  pk: Scalars['ID'];
};

export type QueryListTaskSubmissionsArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  taskId: Scalars['String'];
};

export type QueryListTasksArgs = {
  environment?: Maybe<EnvironmentType>;
  filter?: Maybe<TaskInputFilter>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryListUserSubmissionsArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryListUserTasksArgs = {
  filter?: Maybe<UserTaskInputFilter>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ReferenceFile = {
  __typename?: 'ReferenceFile';
  description?: Maybe<Scalars['String']>;
  s3Key: Scalars['String'];
};

export type ReferenceFileInput = {
  description?: Maybe<Scalars['String']>;
  s3Key: Scalars['String'];
};

export type Settings = {
  __typename?: 'Settings';
  cameraMode?: Maybe<Scalars['Int']>;
  frameCaptureFrequency?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

export type SettingsInput = {
  cameraMode?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  environment?: Maybe<EnvironmentType>;
  frameCaptureFrequency?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type SettingsUpdateInput = {
  cameraMode?: Maybe<Scalars['Int']>;
  frameCaptureFrequency?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SubmissionCount = {
  __typename?: 'SubmissionCount';
  cherry?: Maybe<Scalars['Int']>;
  web?: Maybe<Scalars['Int']>;
};

export enum SubmissionLocation {
  Bedroom = 'BEDROOM',
  DinningRoom = 'DINNING_ROOM',
  Garden = 'GARDEN',
  Hall = 'HALL',
  Kitchen = 'KITCHEN',
  LivingRoom = 'LIVING_ROOM',
  Office = 'OFFICE',
  Unknown = 'UNKNOWN',
}

export enum SubmissionSource {
  Cherry = 'CHERRY',
  Web = 'WEB',
}

export type Task = {
  __typename?: 'Task';
  createdAt: Scalars['String'];
  deepArEffects?: Maybe<DeepArEffects>;
  description: Scalars['String'];
  environment: EnvironmentType;
  expired?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Scalars['String']>;
  forMLTraining?: Maybe<Scalars['Boolean']>;
  highlighted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  people?: Maybe<Scalars['Int']>;
  pk: Scalars['ID'];
  referenceFiles?: Maybe<Array<ReferenceFile>>;
  requiredUploads?: Maybe<Scalars['Int']>;
  submissionCount?: Maybe<SubmissionCount>;
  submitted?: Maybe<Scalars['Boolean']>;
  taskType?: Maybe<TaskType>;
  time?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['String']>;
  userSubmissions?: Maybe<UserSubmissionsConnection>;
  version?: Maybe<Scalars['Int']>;
};

export type TaskUserSubmissionsArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<SortDirection>;
};

export type TaskInput = {
  deepArEffects?: Maybe<DeepArEffectsInput>;
  description: Scalars['String'];
  environment: EnvironmentType;
  expired?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Scalars['String']>;
  forMLTraining: Scalars['Boolean'];
  highlighted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  people: Scalars['Int'];
  referenceFiles?: Maybe<Array<ReferenceFileInput>>;
  requiredUploads?: Maybe<Scalars['Int']>;
  taskType: TaskType;
  time?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

export type TaskInputFilter = {
  name?: Maybe<Scalars['String']>;
};

export type TaskListResult = {
  __typename?: 'TaskListResult';
  items: Array<Task>;
  nextToken?: Maybe<Scalars['String']>;
};

export type TaskStatusUpdateInput = {
  expired?: Maybe<Scalars['Boolean']>;
};

export enum TaskType {
  DeepAr = 'DEEP_AR',
  Exercise = 'EXERCISE',
  Gesture = 'GESTURE',
  Pose = 'POSE',
  UpperBody = 'UPPER_BODY',
}

export type TaskUpdateInput = {
  deepArEffects?: Maybe<DeepArEffectsInput>;
  description?: Maybe<Scalars['String']>;
  environment?: Maybe<EnvironmentType>;
  expired?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Scalars['String']>;
  forMLTraining: Scalars['Boolean'];
  highlighted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  people?: Maybe<Scalars['Int']>;
  referenceFiles?: Maybe<Array<Maybe<ReferenceFileInput>>>;
  requiredUploads?: Maybe<Scalars['Int']>;
  taskType?: Maybe<TaskType>;
  time?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

export type UserCompletedTask = {
  __typename?: 'UserCompletedTask';
  createdAt: Scalars['String'];
  pk: Scalars['ID'];
  sk: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  videoLink?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserSubmission = {
  __typename?: 'UserSubmission';
  createdAt: Scalars['String'];
  metadata: UserSubmissionMetadata;
  pk: Scalars['ID'];
  s3Key: Scalars['String'];
  s3ThumbnailKey?: Maybe<Scalars['String']>;
  s3TranscodedKey?: Maybe<Scalars['String']>;
  sk: Scalars['String'];
  taskId: Scalars['String'];
};

export type UserSubmissionMetadata = {
  __typename?: 'UserSubmissionMetadata';
  appVersion?: Maybe<Scalars['String']>;
  cameraMode?: Maybe<Scalars['String']>;
  device?: Maybe<Scalars['String']>;
  firmwareVersion?: Maybe<Scalars['String']>;
  location: SubmissionLocation;
  source: SubmissionSource;
};

export type UserSubmissionsConnection = {
  __typename?: 'UserSubmissionsConnection';
  items?: Maybe<Array<Maybe<UserSubmission>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UserTaskInputFilter = {
  pk?: Maybe<Scalars['ID']>;
};

export type UserTaskListResult = {
  __typename?: 'UserTaskListResult';
  items: Array<UserCompletedTask>;
  nextToken?: Maybe<Scalars['String']>;
};

export type DeleteUserSubmissionMutationVariables = Exact<{
  pk: Scalars['ID'];
  sk: Scalars['String'];
}>;

export type DeleteUserSubmissionMutation = { __typename?: 'Mutation' } & {
  deleteUserSubmission?: Maybe<{ __typename?: 'UserSubmission' } & Pick<UserSubmission, 'pk'>>;
};

export type CreateTaskMutationVariables = Exact<{
  input?: Maybe<TaskInput>;
}>;

export type CreateTaskMutation = { __typename?: 'Mutation' } & {
  createTask?: Maybe<{ __typename?: 'Task' } & Pick<Task, 'pk'>>;
};

export type UpdateTaskMutationVariables = Exact<{
  pk: Scalars['ID'];
  expectedVersion: Scalars['Int'];
  input?: Maybe<TaskUpdateInput>;
}>;

export type UpdateTaskMutation = { __typename?: 'Mutation' } & {
  updateTask?: Maybe<{ __typename?: 'Task' } & Pick<Task, 'pk'>>;
};

export type CreateSettingsMutationVariables = Exact<{
  input?: Maybe<SettingsInput>;
}>;

export type CreateSettingsMutation = { __typename?: 'Mutation' } & {
  createSettings?: Maybe<
    { __typename?: 'Settings' } & Pick<Settings, 'cameraMode' | 'frameCaptureFrequency' | 'version'>
  >;
};

export type UpdateSettingsMutationVariables = Exact<{
  expectedVersion: Scalars['Int'];
  input?: Maybe<SettingsUpdateInput>;
}>;

export type UpdateSettingsMutation = { __typename?: 'Mutation' } & {
  updateSettings?: Maybe<
    { __typename?: 'Settings' } & Pick<Settings, 'cameraMode' | 'frameCaptureFrequency' | 'version'>
  >;
};

export type ListTasksQueryVariables = Exact<{
  environment?: Maybe<EnvironmentType>;
}>;

export type ListTasksQuery = { __typename?: 'Query' } & {
  listTasks: { __typename?: 'TaskListResult' } & Pick<TaskListResult, 'nextToken'> & {
      items: Array<
        { __typename?: 'Task' } & Pick<
          Task,
          | 'pk'
          | 'name'
          | 'description'
          | 'createdAt'
          | 'expired'
          | 'people'
          | 'taskType'
          | 'time'
          | 'expiryDate'
          | 'environment'
          | 'forMLTraining'
          | 'highlighted'
          | 'submitted'
        >
      >;
    };
};

export type ListTasksForAdminQueryVariables = Exact<{
  environment?: Maybe<EnvironmentType>;
}>;

export type ListTasksForAdminQuery = { __typename?: 'Query' } & {
  listTasks: { __typename?: 'TaskListResult' } & Pick<TaskListResult, 'nextToken'> & {
      items: Array<
        { __typename?: 'Task' } & Pick<
          Task,
          | 'pk'
          | 'name'
          | 'description'
          | 'createdAt'
          | 'expired'
          | 'people'
          | 'taskType'
          | 'time'
          | 'expiryDate'
          | 'environment'
          | 'forMLTraining'
          | 'highlighted'
          | 'submitted'
        > & {
            submissionCount?: Maybe<
              { __typename?: 'SubmissionCount' } & Pick<SubmissionCount, 'web' | 'cherry'>
            >;
          }
      >;
    };
};

export type GetTaskQueryVariables = Exact<{
  pk: Scalars['ID'];
}>;

export type GetTaskQuery = { __typename?: 'Query' } & {
  getTask?: Maybe<
    { __typename?: 'Task' } & Pick<
      Task,
      | 'pk'
      | 'version'
      | 'name'
      | 'description'
      | 'createdAt'
      | 'expired'
      | 'people'
      | 'taskType'
      | 'time'
      | 'expiryDate'
      | 'environment'
      | 'forMLTraining'
      | 'highlighted'
    > & {
        deepArEffects?: Maybe<
          { __typename?: 'DeepArEffects' } & Pick<
            DeepArEffects,
            'arEffects' | 'autoFraming' | 'segmentation'
          >
        >;
        referenceFiles?: Maybe<
          Array<{ __typename?: 'ReferenceFile' } & Pick<ReferenceFile, 's3Key' | 'description'>>
        >;
        userSubmissions?: Maybe<
          { __typename?: 'UserSubmissionsConnection' } & {
            items?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'UserSubmission' } & Pick<
                    UserSubmission,
                    'pk' | 'sk' | 's3Key' | 's3TranscodedKey' | 's3ThumbnailKey' | 'createdAt'
                  > & {
                      metadata: { __typename?: 'UserSubmissionMetadata' } & Pick<
                        UserSubmissionMetadata,
                        'location'
                      >;
                    }
                >
              >
            >;
          }
        >;
      }
  >;
};

export type ListUserSubmissionsQueryVariables = Exact<{ [key: string]: never }>;

export type ListUserSubmissionsQuery = { __typename?: 'Query' } & {
  listUserSubmissions: { __typename?: 'UserTaskListResult' } & {
    items: Array<{ __typename?: 'UserCompletedTask' } & Pick<UserCompletedTask, 'pk' | 'sk'>>;
  };
};

export type ListTaskSubmissionsQueryVariables = Exact<{
  taskId: Scalars['String'];
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListTaskSubmissionsQuery = { __typename?: 'Query' } & {
  listTaskSubmissions?: Maybe<
    { __typename?: 'UserSubmissionsConnection' } & Pick<UserSubmissionsConnection, 'nextToken'> & {
        items?: Maybe<
          Array<
            Maybe<
              { __typename?: 'UserSubmission' } & Pick<
                UserSubmission,
                | 'pk'
                | 'sk'
                | 'taskId'
                | 'createdAt'
                | 's3Key'
                | 's3TranscodedKey'
                | 's3ThumbnailKey'
              > & {
                  metadata: { __typename?: 'UserSubmissionMetadata' } & Pick<
                    UserSubmissionMetadata,
                    | 'location'
                    | 'source'
                    | 'device'
                    | 'appVersion'
                    | 'firmwareVersion'
                    | 'cameraMode'
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type GetSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSettingsQuery = { __typename?: 'Query' } & {
  getSettings?: Maybe<
    { __typename?: 'Settings' } & Pick<Settings, 'cameraMode' | 'frameCaptureFrequency' | 'version'>
  >;
};

export const DeleteUserSubmissionDocument = gql`
  mutation deleteUserSubmission($pk: ID!, $sk: String!) {
    deleteUserSubmission(pk: $pk, sk: $sk) {
      pk
    }
  }
`;
export type DeleteUserSubmissionMutationFn = Apollo.MutationFunction<
  DeleteUserSubmissionMutation,
  DeleteUserSubmissionMutationVariables
>;

/**
 * __useDeleteUserSubmissionMutation__
 *
 * To run a mutation, you first call `useDeleteUserSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSubmissionMutation, { data, loading, error }] = useDeleteUserSubmissionMutation({
 *   variables: {
 *      pk: // value for 'pk'
 *      sk: // value for 'sk'
 *   },
 * });
 */
export function useDeleteUserSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserSubmissionMutation,
    DeleteUserSubmissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserSubmissionMutation, DeleteUserSubmissionMutationVariables>(
    DeleteUserSubmissionDocument,
    options
  );
}
export type DeleteUserSubmissionMutationHookResult = ReturnType<
  typeof useDeleteUserSubmissionMutation
>;
export type DeleteUserSubmissionMutationResult =
  Apollo.MutationResult<DeleteUserSubmissionMutation>;
export type DeleteUserSubmissionMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserSubmissionMutation,
  DeleteUserSubmissionMutationVariables
>;
export const CreateTaskDocument = gql`
  mutation createTask($input: TaskInput) {
    createTask(input: $input) {
      pk
    }
  }
`;
export type CreateTaskMutationFn = Apollo.MutationFunction<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(
    CreateTaskDocument,
    options
  );
}
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;
export const UpdateTaskDocument = gql`
  mutation updateTask($pk: ID!, $expectedVersion: Int!, $input: TaskUpdateInput) {
    updateTask(pk: $pk, expectedVersion: $expectedVersion, input: $input) {
      pk
    }
  }
`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      pk: // value for 'pk'
 *      expectedVersion: // value for 'expectedVersion'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(
    UpdateTaskDocument,
    options
  );
}
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;
export const CreateSettingsDocument = gql`
  mutation createSettings($input: SettingsInput) {
    createSettings(input: $input) {
      cameraMode
      frameCaptureFrequency
      version
    }
  }
`;
export type CreateSettingsMutationFn = Apollo.MutationFunction<
  CreateSettingsMutation,
  CreateSettingsMutationVariables
>;

/**
 * __useCreateSettingsMutation__
 *
 * To run a mutation, you first call `useCreateSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSettingsMutation, { data, loading, error }] = useCreateSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSettingsMutation, CreateSettingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSettingsMutation, CreateSettingsMutationVariables>(
    CreateSettingsDocument,
    options
  );
}
export type CreateSettingsMutationHookResult = ReturnType<typeof useCreateSettingsMutation>;
export type CreateSettingsMutationResult = Apollo.MutationResult<CreateSettingsMutation>;
export type CreateSettingsMutationOptions = Apollo.BaseMutationOptions<
  CreateSettingsMutation,
  CreateSettingsMutationVariables
>;
export const UpdateSettingsDocument = gql`
  mutation updateSettings($expectedVersion: Int!, $input: SettingsUpdateInput) {
    updateSettings(expectedVersion: $expectedVersion, input: $input) {
      cameraMode
      frameCaptureFrequency
      version
    }
  }
`;
export type UpdateSettingsMutationFn = Apollo.MutationFunction<
  UpdateSettingsMutation,
  UpdateSettingsMutationVariables
>;

/**
 * __useUpdateSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingsMutation, { data, loading, error }] = useUpdateSettingsMutation({
 *   variables: {
 *      expectedVersion: // value for 'expectedVersion'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSettingsMutation, UpdateSettingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSettingsMutation, UpdateSettingsMutationVariables>(
    UpdateSettingsDocument,
    options
  );
}
export type UpdateSettingsMutationHookResult = ReturnType<typeof useUpdateSettingsMutation>;
export type UpdateSettingsMutationResult = Apollo.MutationResult<UpdateSettingsMutation>;
export type UpdateSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateSettingsMutation,
  UpdateSettingsMutationVariables
>;
export const ListTasksDocument = gql`
  query listTasks($environment: EnvironmentType) {
    listTasks(environment: $environment) {
      items {
        pk
        name
        description
        createdAt
        expired
        people
        taskType
        time
        expiryDate
        environment
        forMLTraining
        highlighted
        submitted
      }
      nextToken
    }
  }
`;

/**
 * __useListTasksQuery__
 *
 * To run a query within a React component, call `useListTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTasksQuery({
 *   variables: {
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useListTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<ListTasksQuery, ListTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTasksQuery, ListTasksQueryVariables>(ListTasksDocument, options);
}
export function useListTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListTasksQuery, ListTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTasksQuery, ListTasksQueryVariables>(ListTasksDocument, options);
}
export type ListTasksQueryHookResult = ReturnType<typeof useListTasksQuery>;
export type ListTasksLazyQueryHookResult = ReturnType<typeof useListTasksLazyQuery>;
export type ListTasksQueryResult = Apollo.QueryResult<ListTasksQuery, ListTasksQueryVariables>;
export const ListTasksForAdminDocument = gql`
  query listTasksForAdmin($environment: EnvironmentType) {
    listTasks(environment: $environment) {
      items {
        pk
        name
        description
        createdAt
        expired
        people
        taskType
        time
        expiryDate
        environment
        forMLTraining
        highlighted
        submitted
        submissionCount {
          web
          cherry
        }
      }
      nextToken
    }
  }
`;

/**
 * __useListTasksForAdminQuery__
 *
 * To run a query within a React component, call `useListTasksForAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTasksForAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTasksForAdminQuery({
 *   variables: {
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useListTasksForAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<ListTasksForAdminQuery, ListTasksForAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTasksForAdminQuery, ListTasksForAdminQueryVariables>(
    ListTasksForAdminDocument,
    options
  );
}
export function useListTasksForAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListTasksForAdminQuery, ListTasksForAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTasksForAdminQuery, ListTasksForAdminQueryVariables>(
    ListTasksForAdminDocument,
    options
  );
}
export type ListTasksForAdminQueryHookResult = ReturnType<typeof useListTasksForAdminQuery>;
export type ListTasksForAdminLazyQueryHookResult = ReturnType<typeof useListTasksForAdminLazyQuery>;
export type ListTasksForAdminQueryResult = Apollo.QueryResult<
  ListTasksForAdminQuery,
  ListTasksForAdminQueryVariables
>;
export const GetTaskDocument = gql`
  query getTask($pk: ID!) {
    getTask(pk: $pk) {
      pk
      version
      name
      description
      createdAt
      expired
      people
      taskType
      deepArEffects {
        arEffects
        autoFraming
        segmentation
      }
      time
      expiryDate
      environment
      forMLTraining
      highlighted
      referenceFiles {
        s3Key
        description
      }
      userSubmissions {
        items {
          pk
          sk
          s3Key
          s3TranscodedKey
          s3ThumbnailKey
          createdAt
          metadata {
            location
          }
        }
      }
    }
  }
`;

/**
 * __useGetTaskQuery__
 *
 * To run a query within a React component, call `useGetTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskQuery({
 *   variables: {
 *      pk: // value for 'pk'
 *   },
 * });
 */
export function useGetTaskQuery(
  baseOptions: Apollo.QueryHookOptions<GetTaskQuery, GetTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, options);
}
export function useGetTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTaskQuery, GetTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, options);
}
export type GetTaskQueryHookResult = ReturnType<typeof useGetTaskQuery>;
export type GetTaskLazyQueryHookResult = ReturnType<typeof useGetTaskLazyQuery>;
export type GetTaskQueryResult = Apollo.QueryResult<GetTaskQuery, GetTaskQueryVariables>;
export const ListUserSubmissionsDocument = gql`
  query listUserSubmissions {
    listUserSubmissions {
      items {
        pk
        sk
      }
    }
  }
`;

/**
 * __useListUserSubmissionsQuery__
 *
 * To run a query within a React component, call `useListUserSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserSubmissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListUserSubmissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListUserSubmissionsQuery, ListUserSubmissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListUserSubmissionsQuery, ListUserSubmissionsQueryVariables>(
    ListUserSubmissionsDocument,
    options
  );
}
export function useListUserSubmissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUserSubmissionsQuery,
    ListUserSubmissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListUserSubmissionsQuery, ListUserSubmissionsQueryVariables>(
    ListUserSubmissionsDocument,
    options
  );
}
export type ListUserSubmissionsQueryHookResult = ReturnType<typeof useListUserSubmissionsQuery>;
export type ListUserSubmissionsLazyQueryHookResult = ReturnType<
  typeof useListUserSubmissionsLazyQuery
>;
export type ListUserSubmissionsQueryResult = Apollo.QueryResult<
  ListUserSubmissionsQuery,
  ListUserSubmissionsQueryVariables
>;
export const ListTaskSubmissionsDocument = gql`
  query listTaskSubmissions($taskId: String!, $nextToken: String) {
    listTaskSubmissions(taskId: $taskId, nextToken: $nextToken) {
      items {
        pk
        sk
        taskId
        createdAt
        s3Key
        s3TranscodedKey
        s3ThumbnailKey
        metadata {
          location
          source
          device
          appVersion
          firmwareVersion
          cameraMode
        }
      }
      nextToken
    }
  }
`;

/**
 * __useListTaskSubmissionsQuery__
 *
 * To run a query within a React component, call `useListTaskSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTaskSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTaskSubmissionsQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListTaskSubmissionsQuery(
  baseOptions: Apollo.QueryHookOptions<ListTaskSubmissionsQuery, ListTaskSubmissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTaskSubmissionsQuery, ListTaskSubmissionsQueryVariables>(
    ListTaskSubmissionsDocument,
    options
  );
}
export function useListTaskSubmissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTaskSubmissionsQuery,
    ListTaskSubmissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTaskSubmissionsQuery, ListTaskSubmissionsQueryVariables>(
    ListTaskSubmissionsDocument,
    options
  );
}
export type ListTaskSubmissionsQueryHookResult = ReturnType<typeof useListTaskSubmissionsQuery>;
export type ListTaskSubmissionsLazyQueryHookResult = ReturnType<
  typeof useListTaskSubmissionsLazyQuery
>;
export type ListTaskSubmissionsQueryResult = Apollo.QueryResult<
  ListTaskSubmissionsQuery,
  ListTaskSubmissionsQueryVariables
>;
export const GetSettingsDocument = gql`
  query getSettings {
    getSettings {
      cameraMode
      frameCaptureFrequency
      version
    }
  }
`;

/**
 * __useGetSettingsQuery__
 *
 * To run a query within a React component, call `useGetSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSettingsQuery, GetSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSettingsQuery, GetSettingsQueryVariables>(GetSettingsDocument, options);
}
export function useGetSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSettingsQuery, GetSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSettingsQuery, GetSettingsQueryVariables>(
    GetSettingsDocument,
    options
  );
}
export type GetSettingsQueryHookResult = ReturnType<typeof useGetSettingsQuery>;
export type GetSettingsLazyQueryHookResult = ReturnType<typeof useGetSettingsLazyQuery>;
export type GetSettingsQueryResult = Apollo.QueryResult<
  GetSettingsQuery,
  GetSettingsQueryVariables
>;
export type DeepArEffectsKeySpecifier = (
  | 'arEffects'
  | 'autoFraming'
  | 'segmentation'
  | DeepArEffectsKeySpecifier
)[];
export type DeepArEffectsFieldPolicy = {
  arEffects?: FieldPolicy<any> | FieldReadFunction<any>;
  autoFraming?: FieldPolicy<any> | FieldReadFunction<any>;
  segmentation?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MutationKeySpecifier = (
  | 'createSettings'
  | 'createTask'
  | 'deleteUserSubmission'
  | 'updateSettings'
  | 'updateTask'
  | 'updateTaskStatus'
  | MutationKeySpecifier
)[];
export type MutationFieldPolicy = {
  createSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  createTask?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteUserSubmission?: FieldPolicy<any> | FieldReadFunction<any>;
  updateSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTask?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTaskStatus?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type QueryKeySpecifier = (
  | 'getSettings'
  | 'getTask'
  | 'listTaskSubmissions'
  | 'listTasks'
  | 'listUserSubmissions'
  | 'listUserTasks'
  | QueryKeySpecifier
)[];
export type QueryFieldPolicy = {
  getSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  getTask?: FieldPolicy<any> | FieldReadFunction<any>;
  listTaskSubmissions?: FieldPolicy<any> | FieldReadFunction<any>;
  listTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  listUserSubmissions?: FieldPolicy<any> | FieldReadFunction<any>;
  listUserTasks?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReferenceFileKeySpecifier = ('description' | 's3Key' | ReferenceFileKeySpecifier)[];
export type ReferenceFileFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  s3Key?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SettingsKeySpecifier = (
  | 'cameraMode'
  | 'frameCaptureFrequency'
  | 'version'
  | SettingsKeySpecifier
)[];
export type SettingsFieldPolicy = {
  cameraMode?: FieldPolicy<any> | FieldReadFunction<any>;
  frameCaptureFrequency?: FieldPolicy<any> | FieldReadFunction<any>;
  version?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubmissionCountKeySpecifier = ('cherry' | 'web' | SubmissionCountKeySpecifier)[];
export type SubmissionCountFieldPolicy = {
  cherry?: FieldPolicy<any> | FieldReadFunction<any>;
  web?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TaskKeySpecifier = (
  | 'createdAt'
  | 'deepArEffects'
  | 'description'
  | 'environment'
  | 'expired'
  | 'expiryDate'
  | 'forMLTraining'
  | 'highlighted'
  | 'name'
  | 'people'
  | 'pk'
  | 'referenceFiles'
  | 'requiredUploads'
  | 'submissionCount'
  | 'submitted'
  | 'taskType'
  | 'time'
  | 'updatedAt'
  | 'userSubmissions'
  | 'version'
  | TaskKeySpecifier
)[];
export type TaskFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  deepArEffects?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  environment?: FieldPolicy<any> | FieldReadFunction<any>;
  expired?: FieldPolicy<any> | FieldReadFunction<any>;
  expiryDate?: FieldPolicy<any> | FieldReadFunction<any>;
  forMLTraining?: FieldPolicy<any> | FieldReadFunction<any>;
  highlighted?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  people?: FieldPolicy<any> | FieldReadFunction<any>;
  pk?: FieldPolicy<any> | FieldReadFunction<any>;
  referenceFiles?: FieldPolicy<any> | FieldReadFunction<any>;
  requiredUploads?: FieldPolicy<any> | FieldReadFunction<any>;
  submissionCount?: FieldPolicy<any> | FieldReadFunction<any>;
  submitted?: FieldPolicy<any> | FieldReadFunction<any>;
  taskType?: FieldPolicy<any> | FieldReadFunction<any>;
  time?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userSubmissions?: FieldPolicy<any> | FieldReadFunction<any>;
  version?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TaskListResultKeySpecifier = ('items' | 'nextToken' | TaskListResultKeySpecifier)[];
export type TaskListResultFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserCompletedTaskKeySpecifier = (
  | 'createdAt'
  | 'pk'
  | 'sk'
  | 'updatedAt'
  | 'videoLink'
  | UserCompletedTaskKeySpecifier
)[];
export type UserCompletedTaskFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  pk?: FieldPolicy<any> | FieldReadFunction<any>;
  sk?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  videoLink?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserSubmissionKeySpecifier = (
  | 'createdAt'
  | 'metadata'
  | 'pk'
  | 's3Key'
  | 's3ThumbnailKey'
  | 's3TranscodedKey'
  | 'sk'
  | 'taskId'
  | UserSubmissionKeySpecifier
)[];
export type UserSubmissionFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  metadata?: FieldPolicy<any> | FieldReadFunction<any>;
  pk?: FieldPolicy<any> | FieldReadFunction<any>;
  s3Key?: FieldPolicy<any> | FieldReadFunction<any>;
  s3ThumbnailKey?: FieldPolicy<any> | FieldReadFunction<any>;
  s3TranscodedKey?: FieldPolicy<any> | FieldReadFunction<any>;
  sk?: FieldPolicy<any> | FieldReadFunction<any>;
  taskId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserSubmissionMetadataKeySpecifier = (
  | 'appVersion'
  | 'cameraMode'
  | 'device'
  | 'firmwareVersion'
  | 'location'
  | 'source'
  | UserSubmissionMetadataKeySpecifier
)[];
export type UserSubmissionMetadataFieldPolicy = {
  appVersion?: FieldPolicy<any> | FieldReadFunction<any>;
  cameraMode?: FieldPolicy<any> | FieldReadFunction<any>;
  device?: FieldPolicy<any> | FieldReadFunction<any>;
  firmwareVersion?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  source?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserSubmissionsConnectionKeySpecifier = (
  | 'items'
  | 'nextToken'
  | UserSubmissionsConnectionKeySpecifier
)[];
export type UserSubmissionsConnectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserTaskListResultKeySpecifier = (
  | 'items'
  | 'nextToken'
  | UserTaskListResultKeySpecifier
)[];
export type UserTaskListResultFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TypedTypePolicies = TypePolicies & {
  DeepArEffects?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DeepArEffectsKeySpecifier | (() => undefined | DeepArEffectsKeySpecifier);
    fields?: DeepArEffectsFieldPolicy;
  };
  Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier);
    fields?: MutationFieldPolicy;
  };
  Query?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier);
    fields?: QueryFieldPolicy;
  };
  ReferenceFile?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ReferenceFileKeySpecifier | (() => undefined | ReferenceFileKeySpecifier);
    fields?: ReferenceFileFieldPolicy;
  };
  Settings?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SettingsKeySpecifier | (() => undefined | SettingsKeySpecifier);
    fields?: SettingsFieldPolicy;
  };
  SubmissionCount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SubmissionCountKeySpecifier
      | (() => undefined | SubmissionCountKeySpecifier);
    fields?: SubmissionCountFieldPolicy;
  };
  Task?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TaskKeySpecifier | (() => undefined | TaskKeySpecifier);
    fields?: TaskFieldPolicy;
  };
  TaskListResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TaskListResultKeySpecifier | (() => undefined | TaskListResultKeySpecifier);
    fields?: TaskListResultFieldPolicy;
  };
  UserCompletedTask?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserCompletedTaskKeySpecifier
      | (() => undefined | UserCompletedTaskKeySpecifier);
    fields?: UserCompletedTaskFieldPolicy;
  };
  UserSubmission?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UserSubmissionKeySpecifier | (() => undefined | UserSubmissionKeySpecifier);
    fields?: UserSubmissionFieldPolicy;
  };
  UserSubmissionMetadata?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserSubmissionMetadataKeySpecifier
      | (() => undefined | UserSubmissionMetadataKeySpecifier);
    fields?: UserSubmissionMetadataFieldPolicy;
  };
  UserSubmissionsConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserSubmissionsConnectionKeySpecifier
      | (() => undefined | UserSubmissionsConnectionKeySpecifier);
    fields?: UserSubmissionsConnectionFieldPolicy;
  };
  UserTaskListResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserTaskListResultKeySpecifier
      | (() => undefined | UserTaskListResultKeySpecifier);
    fields?: UserTaskListResultFieldPolicy;
  };
};
